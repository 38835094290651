<template>
  <v-container fluid>
    <v-card>
      <v-card-text v-if="!loading && item">
        <v-row>
          <v-col>
            <v-sheet rounded outlined>
              <v-card-title class="justify-center text-center my-2 text-break">
                {{ item.SchoolName }} <br>
                TBT BİREYSEL DEĞERLENDİRME KARNESİ
              </v-card-title>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-sheet rounded outlined>
              <v-card-title class="justify-center text-center">
                {{ item.FormName }}
              </v-card-title>
              <v-card-subtitle v-if="item.FormDate != null" class="justify-center text-center">
                {{ item.FormDate | moment }}
              </v-card-subtitle>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-sheet rounded outlined min-height="400">
              <apex-chart
                  class="mt-2"
                  type="bar"
                  height="350"
                  :options="chartOptions"
                  :series="series"
                  :width="chartWidth"
              ></apex-chart>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-sheet rounded outlined>
              <v-card-title class="justify-center text-center" style="color: red; word-wrap: break-word"
              >BİLİŞSEL BECERİ DÜZEYİ <br v-if="$vuetify.breakpoint.mobile"/> ÇALIŞMALARI
              </v-card-title
              >
              <v-container>
                <v-row class="d-flex justify-content-center flex-wrap">
                  <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '3'">
                    <v-sheet
                        outlined
                        rounded
                        color="primary"
                        class="mx-2"
                        :min-height="$vuetify.breakpoint.mobile ? '50' : '120'"
                    >
                      <v-card-title class="justify-center pb-0" style="color: white"
                      >GELİŞİM 1
                      </v-card-title
                      >
                      <v-card-title class="justify-center" style="color: white"
                      >KAVRAMA
                      </v-card-title
                      >
                    </v-sheet>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '3'">
                    <v-sheet
                        outlined
                        rounded
                        color="error"
                        class="mx-2"
                        :min-height="$vuetify.breakpoint.mobile ? '50' : '120'"
                    >
                      <v-card-title class="justify-center pb-0" style="color: white"
                      >GELİŞİM 2
                      </v-card-title
                      >
                      <v-card-title class="justify-center text-center" style="color: white"
                      >UYGULAMA
                      </v-card-title
                      >
                    </v-sheet>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '3'">
                    <v-sheet
                        outlined
                        rounded
                        color="warning"
                        class="mx-2"
                        :min-height="$vuetify.breakpoint.mobile ? '50' : '120'"
                    >
                      <v-card-title class="justify-center pb-0" style="color: white"
                      >GELİŞİM 3
                      </v-card-title
                      >
                      <v-card-title class="justify-center" style="color: white"
                      >ANALİZ SENTEZ
                      </v-card-title
                      >
                    </v-sheet>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '3'">
                    <v-sheet
                        outlined
                        rounded
                        color="success"
                        class="mx-2"
                        :min-height="$vuetify.breakpoint.mobile ? '50' : '120'"
                    >
                      <v-card-title class="justify-center pb-0" style="color: white"
                      >GELİŞİM 4
                      </v-card-title
                      >
                      <v-card-title
                          class="justify-center text-center"
                          style="color: white"
                      >DEĞERLENDİRME
                      </v-card-title
                      >
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment/moment'

export default {
  name: 'elementary-form-user-report',
  data() {
    return {
      formId: this.$route.params.id,
      rows: [],
      loading: true,
      pointTemplates: [],
      rateWatcher: false,
      item: {},
      series: [
        {
          name: 'PUAN',
          data: []
        }
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            endingShape: 'rounded',
            borderRadius: 3,
            distributed: true
          }
        },
        responsive: [
          {
            breakpoint: 300
          }
        ],
        colors: ['#5867dd', '#ffb822', '#0abb87', '#fd397a'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: false
        },
        xaxis: {
          categories: [
            ['', ''],
            ['', ''],
            ['', ''],
            ['', '']
          ],
          labels: {
            rotate: -60,
            minHeight: 50,
          }
        },
        yaxis: {
          show: false
        },
        fill: {
          opacity: 1
        },
        toolbar: {
          show: false
        },
        legend: {
          show: false
        },
        grid: {
          show: false
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: () => '',
            title: {
              formatter: () => '',
            },
          },
        }
      }
    }
  },
  watch: {
    rateWatcher(val) {
      if (val) this.rater(this.rows, this.pointTemplates)
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).locale('tr').format('DD.MM.YYYY') : ''
    }
  },
  created() {
    this.getPointTemplates()
  },
  computed: {
    dynamicWidth() {
      return this.series[0].data.length * 10
    },

    chartWidth() {
      return this.dynamicWidth < window.innerWidth ? '100%' : this.dynamicWidth
    }
  },
  mounted() {
    this.getFormDetails()
    if (this.$vuetify.breakpoint.mobile) this.chartOptions.xaxis.labels.rotate = -60
  },
  methods: {
    rater(rows, pointTemplates) {
      //rate finder method start
      let pointTemplate =
          rows[0].FormUser.Form.PointTemplateId > 0
              ? pointTemplates.find((d) => d.Id === rows[0].FormUser.Form.PointTemplateId)
              : null
      if (pointTemplate) {
        pointTemplate.PointTemplateSections.sort(function (a, b) {
          return a.MinValue - b.MinValue
        })
      }
      let advancedPointTemplate =
          rows[0].FormUser.Form.AdvancedPointTemplateId > 0
              ? pointTemplates.find((d) => d.Id === rows[0].FormUser.Form.AdvancedPointTemplateId)
              : null
      if (advancedPointTemplate) {
        advancedPointTemplate.PointTemplateSections.sort(function (a, b) {
          return a.MinValue - b.MinValue
        })
      }
      rows.map((s) => {
        let rate = (s.Point / s.MaxPoint) * 100
        if (pointTemplate) {
          pointTemplate.PointTemplateSections.map((d) => {
            if (rate >= d.MinValue) s.PointTemplate = d.Name
          })
        }
        if (advancedPointTemplate) {
          advancedPointTemplate.PointTemplateSections.map((d) => {
            if (rate >= d.MinValue) s.AdvancedPointTemplate = d.Name
          })
        }
      })

      for (let i = 0; i < this.chartOptions.xaxis.categories.length; i++) {
        this.chartOptions.xaxis.categories[i][0] = this.rows[i].AssessmentArea.Name
        this.chartOptions.xaxis.categories[i][1] = this.rows[i].AdvancedPointTemplate ?? ''
      }

      for (let i = 0; i < this.chartOptions.xaxis.categories.length; i++) {
        this.chartOptions.colors[i] = this.chartOptions.xaxis.categories[i].includes('GELİŞİM1')
            ? '#5867dd'
            : this.chartOptions.xaxis.categories[i].includes('GELİŞİM2')
                ? '#fd397a'
                : this.chartOptions.xaxis.categories[i].includes('GELİŞİM3')
                    ? '#ffb822'
                    : this.chartOptions.xaxis.categories[i].includes('GELİŞİM4')
                        ? '#0abb87'
                        : '#e8ecfa'
      }

      this.loading = false
      //rate finder method end
    },
    
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/Form/FormUserAssessmentArea', {
        sortBy: ['Id'],
        descending: [true],
        rowsPerPage: -1,
        page: 1,
        Query: {FormId: this.formId}
      })
          .then((data) => {
            this.rows = data.data.Results
            this.rateWatcher = this.rows.length > 0 && this.pointTemplates.length > 0;

            this.series = [
              {
                name: 'PUAN',
                data: this.rows.map((d) => d.Point)
              }
            ]
          })
          .catch(({response}) => {
            this.loading = false
            ApiService.showError(response)
          })
    },

    getPointTemplates() {
      ApiService.setHeader()
      ApiService.post('api/PointTemplate', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: -1,
        page: 1
      })
          .then((data) => {
            this.pointTemplates = data.data.Results
            this.rateWatcher = this.rows.length > 0 && this.pointTemplates.length > 0;
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
    
    getFormDetails() {
      ApiService.setHeader()
      ApiService.get('api/Form/FormUserReport/' + 0 + '/' + this.formId)
          .then((data) => {
            this.item = data.data
            this.getDataFromApi()
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    }
  }
}
</script>
